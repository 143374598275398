import { useState, useEffect } from 'react';
import axios from 'axios';

const ImageMemeApi = () => {
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 12;

  useEffect(() => {
    // Replace 'API_ENDPOINT' with the actual API endpoint to fetch images.
    axios.get('https://programming-memes-images.p.rapidapi.com/v1/memes',{
      headers: {
        'X-RapidAPI-Key': '96f1a232afmsh6bf05182bb40528p192bacjsnd46b7ce52b77',
        'X-RapidAPI-Host': 'programming-memes-images.p.rapidapi.com'
      },
    },)
      .then((response) => {
        setImages(response.data);
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
      });
  }, []);

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-3 gap-4">
        {currentImages.map((image) => (
          <div key={image.id} className="p-4">
            <div className="bg-white shadow-lg rounded-lg">
              <img
                src={image.image}
                alt={image.id}
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h2 className="text-xl font-semibold">{image.upvote}</h2>
                <p className="text-gray-600">{image.downvote}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-4">
        {images.length > imagesPerPage && (
          <ul className="flex justify-center">
            {Array.from({ length: Math.ceil(images.length / imagesPerPage) }, (_, index) => (
              <li
                key={index}
                className={`mx-2 cursor-pointer ${
                  index + 1 === currentPage ? 'text-blue-600 font-semibold' : 'text-gray-500'
                }`}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ImageMemeApi;
