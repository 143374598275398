import ImageMemeApi from './components/ImageMemeApi';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1 className="text-3xl font-semibold text-center my-8">Created this lovely page, to keep you refreshed Oyindamola, Don't mind me not having time to do much styling with TailwindCss. </h1>
      <ImageMemeApi />
    </div>
  );
}

export default App;
